.footer-links {
    display: flex;
    justify-content: space-evenly;
    padding: 30px 0;
}

.linked {
    width: 24%;
}

.linked h3 {
    font-size: 18px;
    padding-bottom: 13px;
}

.linked ul li a {
    display: block;
    padding: 6px 0;
}

.linked ul {
    padding-bottom: 39px;
}

.linked ul li a:hover {
    color: var(--pri-col);
}

a.btm-linkd {
    padding: 8px;
    border: 1px solid var(--pri-col);
    margin-top: 8px;
    display: block;
    width: 40%;
    color: var(--pri-col);
}

@media(max-width: 500px){
    .footer-links {
        display: flex;
        flex-wrap: wrap;
    }

    .linked {
        width: 45%;
    }

    a.btm-linkd {
        width: 65%;
    }
}

@media(max-width: 500px){
    .linked h3 {
        font-size: 13px;
        padding-bottom: 4px;
    }

    .linked ul li a {
        display: block;
        padding: 3px 0;
    }
}