.dashboard {
    width: 100vw;
    min-height: 80vh;
  }
  
  .dashboard .cont {
    width: 100%;
    margin: 0 auto;
    height: 100%;
  }
  
  .act-header {
    display: flex;
    justify-content: space-around;
    padding: 15px;
  }
  
  .act-balance {
    width: 45%;
  }
  
  .act-balance h3 {
    font-size: 13px;
    color: var(--pri-col);
  }
  
  .act-balance h4 {
    font-size: 20px;
    color: green;
  }
  
  .act-dets {
    width: 45%;
    display: grid;
    justify-content: center;
  }
  
  .act-dets h2 {
    font-size: 13px;
    color: var(--pri-col);
    font-weight: 700;
  }
  
  .act-dets p {
    font-weight: 700;
    font-size: 16px;
  }
  
  ul.list-links {
    display: flex;
    padding: 14px 8px;
    justify-content: space-between;
    background: var(--sec-col);
  }
  
  ul.list-links li {
    width: 24%;
  }
  
  ul.list-links li a {
    font-weight: 600;
    font-size: 13px;
    color: #67212e;
  }
  
  ul.list-links li a:hover {
    text-decoration: underline;
  }
  
  .all-bal {
    color: var(--pri-col);
    padding: 10px;
  }
  
  .all-bal ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .all-bal ul h2 {
    width: 100%;
    padding: 8px 0;
    font-size: 15px;
    color: #324579;
  }
  
  .all-bal ul li {
    width: 30%;
    text-align: center;
    padding: 10px;
  }
  
  .all-bal ul li h4 {
    font-size: 13px;
  }
  
  .all-bal ul li p {
    color: #6173a5;
    font-weight: 600;
  }
  
  .loan-ads {
    height: 300px;
    display: grid;
    align-content: center;
    position: relative;
    padding-left: 50px;
    background: url("../assets/deposi.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .loan-ads h3 {
    color: var(--buttoned);
    z-index: 1;
    font-size: 33px;
  }
  
  .loan-ads p {
    color: #fff;
    z-index: 1;
    padding-bottom: 10px;
    font-weight: 600;
  }
  
  .loan-ads a {
    color: var(--sec-col);
    text-decoration: underline;
    font-size: 15px;
    z-index: 1;
    padding: 10px 0;
    font-weight: 500;
  }
  
  .card-ads {
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 42px 0;
    background: var(--sec-col-lite);
  }
  
  .card-ads-cont {
    width: 85%;
    padding: 21px 33px;
    border-radius: 8px;
    background: var(--pri-col);
    box-shadow: 6px 6px 3px #13172278;
  }
  
  .card-ads-cont > * {
    color: #fff;
  }
  
  .card-ads-cont h4 {
    padding-top: 30px;
    font-size: 18px;
  }
  
  .card-ads-cont h2 {
    padding: 15px 0;
    font-size: 30px;
  }
  
  .card-ads-cont > p {
    padding: 20px 0;
  }
  
  .card-ads-cont ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .card-ads-cont ul li {
    width: 40%;
    display: flex;
    align-items: center;
    padding: 8px 0;
  }
  
  .card-ads-cont ul li p {
    padding-left: 11px;
  }
  
  .card-ads-cont a {
    padding: 11px 0;
    display: block;
    color: var(var(--sec-col-lite));
    text-decoration: underline;
  }
  
  .card-ads-cont a:hover {
    color: var(--sec-col);
    transition: 1s ease;
  }
  
  .card-ads-cont > h5 {
    padding: 11px 0;
  }
  
  .why-choose {
    height: 200px;
    display: grid;
    align-content: center;
    background: url("../../dashboard/assets/fun-facts-bg.jpg");
    padding: 21px;
    text-align: center;
    justify-items: center;
  }
  
  .why-choose h4 {
    font-size: 18px;
    color: #fff;
  }
  
  .why-choose h2 {
    padding: 12px;
    color: #fff;
    width: 75%;
  }
  
  .vision {
    display: flex;
    padding-bottom: 45px;
  }
  
  .vision .img {
    width: 40%;
    background: url("../assets/banking-2.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .vision .dets {
    width: 60%;
    padding: 20px;
    color: var(--pri-col);
  }
  
  .vision .dets h2 {
    color: var(--svg);
    font-size: 21px;
    padding: 11px 0;
  }
  
  .colR {
    color: var(--svg);
  }
  
  .colG {
    color: green;
  }
  
  @media (max-width: 500px) {
    .dashboard .cont {
      width: 100%;
    }
  
    .all-bal ul li h4 {
      font-size: 10px;
  }
  
    ul.list-links li a {
      font-size: 10px;
    }
  
    .loan-ads {
      height: 222px;
    }
  
    .loan-ads a {
      font-size: 10px;
    }
  
    .card-ads-cont {
      width: 90%;
    }
  
    .card-ads-cont h4 {
      font-size: 14px;
    }
  
    .card-ads-cont h2 {
      font-size: 18px;
    }
  
    .why-choose h2 {
      width: 80%;
      font-size: 15px;
    }
  
    .why-choose h4 {
      font-size: 13px;
    }
  
    .vision .dets h2 {
      font-size: 15px;
    }
  
    .card-ads-cont > h5 {
      font-size: 9px;
    }
  }
  