.simulation {
    width: 100%;
    position: relative;
    padding: 60px 0;
}


.simulation {
    width: 100%;
    position: relative;
    padding: 60px 0;
}

.simulation .cont {
    width: 80%;
    margin: 0 auto;
    padding: 15px 25px;
    border: 1px solid gainsboro;
    border-radius: 4px;
    box-shadow: 2px 2px 8px var(--pri-col);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
}

.simulator {
    width: 45%;
}

.simulator h3 {
    color: var(--svg);
    padding-bottom: 21px;
}

.simulation .cont .result {
    width: 45%;
}

.simulation .cont .result ul li {
    display: flex;
    padding: 8px 0;
    justify-content: space-between;
}

.simulation .cont .result h3 {
    color: var(--pri-col);
    padding-bottom: 40px;
}

.simulation .cont .result ul li h4 {
    color: var(--buttoned);
}

.simulator form p {
    font-size: 13px;
}

.simulator form p span {
    font-weight: 700;
}

.simulator form button {
    background: var(--pri-col);
    margin: 13px;
    padding: 9px 13px;
}

h5.resetBut {
    background: var(--svg);
    width: 31%;
    text-align: center;
    padding: 4px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
}

h5.resetBut:hover {
    background: gray;
}

.bottled p {
    font-size: 13px;
    padding: 33px 0;
}

@media(max-width: 500px){
    .simulator form p {
        font-size: 9px;
    }

    .simulator form button {
        margin: 7px;
        padding: 6px 6px;
    }

    h5.resetBut {
        width: 60%;
    }

    .simulation .cont .result ul li h4 {
        font-size: 12px;
    }
}