header * {
  transition: 0.5s linear(0 0%, 1 100%);
}

.mobile {
  display: none !important;
}


header.headed {
  position: fixed;
  background: var(--pri-col);
  z-index: 999;
}

.lap-nav{
  height: 13vh;
  width: 100vw;
  display: flex;
  box-shadow: 0px 3px 9px 3px rgba(0,0,0,0.87);
}

.laptop .foot-nav {
  width: 22%;
}

.foot-nav {
  display: grid;
}

.lap-nav * {
  color: #fff;
}

.lap-nav .logo {
  display: flex;
  width: 27%;
  height: inherit;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.lap-nav .logo a {
  display: flex;
  align-items: center;
}

.lap-nav .logo a h2 {
  line-height: 0;
}

.lap-nav .logo a h2 svg {
  fill: gainsboro;
  font-size: 33px;
}

.lap-nav .logo a h4 {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 1.3;
}

.lap-nav .logo h5 {
  font-size: 9px;
}

ul.main-menu {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

ul.main-menu li {
  width: 20%;
  text-align: center;
  height: 45%;
  display: grid;
  align-items: center;
}

ul.main-menu li a {
  width: 98%;
  height: 98%;
  display: grid;
  align-items: center;
  margin: 1%;
  font-size: 13px;
}

ul.side-menu {
  width: 20%;
  display: flex;
}

.left-side-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #fff;
}

ul.side-menu li {
  height: 30%;
  display: grid;
  align-items: center;
}

ul.side-menu li a {
  width: 100%;
  display: block;
  font-size: 11px;
  color: var(--pri-col);
  padding: 0 4px;
}

ul.side-menu > a {
  height: 40%;
  width: 43%;
  align-self: center;
  margin: 0 auto;
}

ul.side-menu > a h5 {
  font-size: 14px;
  border: 1px solid;
  border-radius: 3px;
  color: var(--pri-col);
  text-align: center;
  padding: 1px;
  background: #fff;
}

ul.side-menu > a {
  height: 40%;
  width: 43%;
  align-self: center;
}

h3.teleLink {
  position: fixed;
  bottom: 45px;
  right: 21px;
  z-index: 999;
}

h3.teleLink.showLC{
  opacity: 1;
  transition: all 1s ease;
}

h3.teleLink.hideLC{
  opacity: 0;
  transition: all 1s ease;
}

h3.teleLink a {
  display: flex;
  flex-direction: column;
  font-size: 9px;
  color: #fff;
  background: #2962ff;
  width: 80px;
  height: 80px;
  padding: 8px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

h3.teleLink a svg {
  font-size: 21px;
  fill: #fff;
}

h3.teleLink.showLC.wrn a{
  background: #c10000;
}

@media (max-width: 800px) {


}

@media (max-width: 550px) {
  .laptop {
    display: none !important;
  }

  .mobile {
    display: grid !important;
  }

  .main-nav {
    height: 10vh;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px 3px 9px 3px rgba(0,0,0,0.87);
  }

  .main-nav .lap-nav {
    box-shadow: none;
}

.foot-nav {
  display: grid;
  width: 15%;
  justify-items: center;
}

.foot-nav .logo a h4 {
  display: flex;
  flex-direction: column;
}

ul.nav-mobile-menu {
  height: 0;
  transition: 1s ease;
  overflow: hidden;
  color: var(--pri-col);
  background: #fff;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.87);
  display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

ul.nav-mobile-menu.activeMobileNav {
  height: 33vh;
  border-bottom: 1px solid var(--pri-col);
  transition: 1s ease;
}

ul.nav-mobile-menu li {
  display: grid;
  justify-content: center;
  height: 30px;
  align-items: center;
}

ul.nav-mobile-menu li a {
  color: var(--pri-col);
  display: block;
  font-size: 15px;
}

.logo {
  width: 80%;
  padding-left: 25px;
  display: flex;
  align-items: center;
}

.logo h2 {
  line-height: 0;
}

.logo h2 svg {
  fill: #fff;
  font-size: 27px;
}

.logo a {
  color: #fff;
  display: grid;
  padding: 3px;
}

.nav-switch {
  width: 20%;
  display: grid;
  justify-items: center;
}

.nav-switch h2 {
  line-height: 0;
}

.nav-switch h2 svg {
  fill: #fff;
  font-size: 39px;
}

.headt {
  padding-top: 13vh;
}
}

@media(max-width: 500px){
  .afted:after {
    left: 60%;
  }

  h2.afted {
    font-size: 24px;
  }

  p {
    font-size: 11px;
}

a {
  font-size: 10px;
}

h3 {
  font-size: 15px;
}

h5.wht {
  color: #fff !important;
  font-size: 11px;
}

.foot-nav .logo a h4 {
  font-size: 11px;
}

.logo h2 svg {
  fill: #fff;
  font-size: 33px;
}
}