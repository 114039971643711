.cta{
    background-image: url("../assets/cta.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 60px 0;
    position: relative;
}

.cta .cont {
    width: 80%;
    margin: auto;
    position: relative;
}

.cta .cont * {
    color: #fff;
}

.cta .cont a {
    position: absolute;
    right: 0;
    top: 0%;
    background: var(--svg);
    padding: 15px;
    border-radius: 24px 0;
}

.cta .cont p {
    width: 50%;
}

@media(max-width: 500px){
    
}