.banned * {
  color: #fff;
  position: relative;
}

.banned {
  z-index: 2;
  position: relative;
}

.banned-container ul {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.banned-container ul {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
}

.banned-container ul li {
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/slider03.jpg");
  background-position: center;
  background-size: cover;
  animation: bnnrChange 30s infinite;
  animation-timing-function: step-start;
  position: relative;
  z-index: -1;
  overflow: hidden;
}

.banned-container ul li h2 {
  font-size: 40px;
  width: 70%;
}

.banned-container ul li h2 span {
  color: var(--buttoned);
  font-size: 36px;
  text-transform: uppercase;
}

.banned-container ul li h4 {
  width: 70%;
  padding: 11px 0;
  font-size: 15px;
}

.banned-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
}

.banned-links a {
  width: 45%;
  color: #fff;
  background: var(--pri-col);
  padding: 13px;
  border: 2px solid #000;
  border-radius: 0px 30px;
  text-align: center;
}

.banned-container ul li > div {
  position: absolute;
}

.bnnr1 {
  animation: bannr1 30s infinite;
  padding-left: 21px;
}

.bnnr2 {
  animation: bannr2 30s infinite;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 21px;
  text-align: right;
}

.bnnr3 {
  animation: bannr3 30s infinite;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes bnnrChange {
  0%,
  100% {
    background-image: url("../assets/slider01.jpg");
  }
  33% {
    background-image: url("../assets/slider02.jpg");
  }
  66% {
    background-image: url("../assets/slider03.jpg");
  }
}

@keyframes bannr1 {
  0%,
  100% {
    transform: translateX(-100%);
    opacity: 0;
    display: none;
  }
  3% {
    transform: translateX(0%);
    opacity: 1;
    display: block;
  }
  30% {
    transform: translateX(0%);
    opacity: 1;
    display: block;
  }
  32% {
    transform: translateX(-99%);
    opacity: 0;
    display: none;
  }
}

@keyframes bannr2 {
  0%,
  100% {
    transform: translateX(100%);
    opacity: 0;
    display: none;
  }
  33% {
    transform: translateX(99%);
    opacity: 0;
    display: none;
  }
  36% {
    transform: translateX(0%);
    opacity: 1;
    display: flex;
  }
  62% {
    transform: translateX(0%);
    opacity: 1;
    display: flex;
  }
  63% {
    transform: translateX(99%);
    opacity: 0;
    display: none;
  }
}
@keyframes bannr3 {
  0%,
  100% {
    transform: translateY(100%);
    opacity: 0;
    display: none;
  }
  66% {
    transform: translateY(99%);
    opacity: 0;
    display: none;
  }
  69% {
    transform: translateY(0%);
    opacity: 1;
    display: block;
  }
  96% {
    transform: translateY(0%);
    opacity: 1;
    display: block;
  }
  99% {
    transform: translateY(100%);
    opacity: 0;
    display: none;
  }
}

@media (max-width: 500px) {
  .banned-container ul li > div h2 {
    font-size: 27px;
  }

  .banned-container ul li > div h2 span {
    font-size: 30px;
  }

  .banned-container ul li h4 {
    font-size: 13px;
  }

  .banned-links a {
    padding: 8px 11px;
    border: 1px outset #000;
    font-size: 11px;
  }
}
