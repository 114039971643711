.insurance {
    background: var(--sec-col-lite);
    padding: 40px 0;
    width: 100%;
    position: relative;
}

.insurance .cont {
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.insurance .cont > p {
    padding: 21px 0;
}

.insurance .cont ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.insurance .cont ul li {
    width: 45%;
    display: flex;
    margin-bottom: 30px;
    padding: 15px 0;
}

.insurance .cont ul li svg {
    font-size: 45px;
    width: 30%;
    fill: var(--pri-col);
}

.insurance .cont ul li .div {
    width: 70%;
}

.insurance .cont ul li .div h3 {
    color: var(--pri-col);
}

@media(max-width: 500px){
    .insurance .cont ul li svg {
        font-size: 30px;
    }
    
}