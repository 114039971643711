header.heading {
    background: var(--pri-col);
    padding: 4px;
    display: grid;
}

header.heading .foot-nav {
    display: grid;
    justify-items: center;
    padding: 4px;
}

.foot-nav .logo {
    width: 50%;
    display: flex;
    height: inherit;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

header.heading .foot-nav .logo a h2 svg {
    font-size: 33px;
}

header.heading .foot-nav .logo a h4 {
    display: flex;
    flex-direction: column;
    font-size: 13px;
}

.tradingview-widget-container.floater {
    border: 1px solid var(--pri-col);
    border-width: 0 0 1px 0;
}

@media (max-width: 800px) {

}

@media (max-width: 550px) {

}
