.live-trader{
    background: #000;
}

.headingTab {
    align-items: flex-end;
    display: flex;
    height: 50px;
    justify-content: center;
    padding-bottom: 15px;
    text-transform: uppercase;
    color: #fff;
}

.tradingview-widget-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

div#tradingview_cd17d {
    height: 65vh;
    width: 80%;
    margin: 0 auto;
}

.tradingview-widget-container.trad_cont {
    height: 100vh;
    width: 80%;
    margin: 4px auto;
}

.tradingview-widget-container.trad_cont iframe {
    padding-top: 30px;
    height: 80vh;
}

@media(max-width: 500px){
    .tradingview-widget-container.trad_cont,
    div#tradingview_cd17d {
        height: 100vh;
        width: 100%;
    }

}