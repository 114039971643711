@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sirin+Stencil&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,300;0,500;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,800;1,300;1,800&display=swap');

.bo{
  font-family: 'Sirin Stencil', cursive;
  font-family: 'Lobster', cursive;
  font-family: 'DM Serif Display', serif;
  font-family: 'Roboto Slab', serif;
  font-family: 'Josefin Sans', sans-serif;
  font-family: 'Exo 2', sans-serif;
}

:root {
  --pri-col: #1e222d;
  --sec-col: #b0bec5;
  --sec-col-lite: #e3f2fd;
  --svg: crimson;
  --svg-lite: #ffb6c4;
  --buttoned: #FED100;

}

* {
  list-style: none;
  text-decoration: none;
  margin-block-start: 0;
  margin-block-end: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Exo 2', sans-serif;
  position: relative;
  max-width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
  margin: 0;
  line-height: 1.3;
}

a {
  text-decoration: none;
  color: #000;
}

ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

.wrapper {
  position: absolute;
  width: 100vw;
  overflow: hidden;
  background: #eeeeee40;
}

h2.afted {
  position: relative;
  color: var(--svg)
}

.afted:after {
  content: "";
  width: 20%;
  height: 4px;
  display: block;
  background: var(--svg);
  position: absolute;
  bottom: 45%;
  left: 20%;
  box-shadow: 2px 2px 2px gray;
  border-radius: 50%;
}

button{
  cursor: pointer;
}

.anihideIn{
  opacity: 0;
  transform: scale(0);
  transition: all 1s;
  transition-delay: calc(100ms * var(--i));
}

.anishowOut{
  opacity: 1;
  transform: scale(1);
}

.anihideVis{
  opacity: 0;
  transition: all 1s;
  transition-delay: calc(100ms * var(--i));
}

.anishowVis{
  opacity: 1;
}

.anihideDown {
  opacity: 0;
  transform: translateY(50%);
  transition: all 1s;
  transition-delay: calc(100ms * var(--i));
}
.anishowUp {
  opacity: 1;
  transform: translateY(0);
}
.anihideLeft {
  opacity: 0;
  transform: translateX(-50%);
  transition: all 1s;
  transition-delay: calc(100ms * var(--i));
}
.anishowRight {
  opacity: 1;
  transform: translateX(0);
}
.anihideRight {
  opacity: 0;
  transform: translateX(50%);
  transition: all 1s;
  transition-delay: calc(100ms * var(--i));
}
.anishowLeft {
  opacity: 1;
  transform: translateX(0);
}

.floater{
  width: 100vw;
}

.wrn {
  color: crimson !important;
}

.mh1 {
  min-height: 100vh;
}

.beforeWas::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #000000c4;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}

.zl1 {
  position: relative;
  z-index: 1;
}

.lgwd8 {
  width: 80%;
  margin: 0 auto;
  display: grid;
}

form.filler input:valid {
  color: var(--pri-col);
  border: 1px solid var(--pri-col);
}

form.filler input:invalid {
  border-radius: 4px 6px 6px 4px;
  color: var(--svg);
}

form.filler input:focus {
  color: var(--buttoned);
  background: var(--pri-col)
}

form.filler input {
  padding: 8px;
  margin-bottom: 21px;
  margin-right: 13px;
  border: 1px solid var(--pri-col);
  font-size: 16px;
  border-radius: 4px;
}

.hoved {
  display: inline-block;
  vertical-align: middle;
  color: #fff !important;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  border-radius: 0 20px 0 20px;
  padding: 14px 30px 10px;
  position: relative;
  overflow: hidden;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.hoved:hover:before {
  webkit-transform: scale(2);
  transform: scale(2);
}

.hoved:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--buttoned);
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.index-bg {
  color: var(--sec-col);
  padding-bottom: 18vh;
}

@media(max-width: 500px){
  form.filler input {
    padding: 6px;
    margin-bottom: 12px;
    margin-right: 8px;
    font-size: 12px;
    width: 100%;
}
}