.card {
  background: #dcdcdc;
  padding: 20px 0;
  width: 100%;
  display: grid;
  justify-items: center;
  color: #000;
}

.card .cont {
  width: 90%;
  background: #fff;
  border: 3px solid #b2b5be9c;
  border-radius: 11px;
  overflow: hidden;
  box-shadow: 3px 6px 9px 6px #6a6d78;
}

.card .cont .body > h3 {
  background: #fcb116;
  color: #000;
  padding: 17px 26px;
  font-size: 15px;
}

.card-select {
  display: flex;
  padding: 0 10px;
}

.card-view {
  width: 35%;
}

.card-view h2 {
  padding: 5% 4%;
  font-size: 24px;
}

.card-img {
  width: 100%;
  display: grid;
  justify-items: center;
  padding: 24px 0;
}

.card-img p {
  width: 95%;
  height: 143px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 13px;
}

.cards-list {
  width: 65%;
}

.cards-list > p {
  font-size: 13px;
  padding: 14px 0;
}

.cards-list ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.cards-list ul li {
  cursor: pointer;
  margin: 8px 0;
  width: 30%;
  padding: 3px;
}

li.activeCard {
  border: 2px solid #004d40;
  border-radius: 10px;
}

.cards-list ul li p {
  width: 100%;
  height: 83px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
}

.information {
  padding: 11px 10px;
  padding-left: 35%;
}

.information > h2 {
  padding: 21px 0;
}

.cards-list {
  width: 65%;
  padding: 20px 0;
  border-bottom: 3px solid #80808036;
}

.information p {
  font-size: 14px;
}

.card .information form input {
  border: 2px solid #80808073;
  border-radius: 6px 6px 0 0;
  width: 100%;
}

.card .information form input {
  color: #000;
}

.card .information form .form-cont label {
  font-size: 14px;
  padding-left: 11px;
  color: #808080;
}

input#md {
  width: unset;
}

.card .information form .form-cont {
  margin-bottom: 39px;
}

.igroups {
  border-bottom: 2px dotted #8080805c;
  padding: 45px 0;
}

.chbx {
  display: flex;
  align-items: center;
}

.chbx label p {
  color: #000;
}

.igroups input#dob {
  width: 80%;
  padding: 6px;
}

.igroups p {
  color: #808080;
  padding-left: 14px;
}

.igroups select {
  width: 100%;
  padding: 12px 0;
  border-radius: 6px 6px 0 0;
  border: 2px solid #b2b5be;
  color: #808080;
}

.termsCondition {
  padding: 40px 0;
}

.termsCondition > h2 {
  color: #004d40;
  padding-bottom: 20px;
}

.termsCondition > p {
  font-size: 14px;
}

.text-call-disc {
  margin: 10px 0;
  border: 3px solid gainsboro;
  border-radius: 5px;
  max-height: 250px;
  min-height: 250px;
  overflow-y: scroll;
  padding: 10px 20px;
}

.information p {
  font-size: 14px;
  color: #000;
}

.text-call-disc p span {
  font-size: 16px;
  font-weight: 700;
  margin: 9px 0;
  display: block;
}

.important-info {
  margin: 40px 0;
  border: 3px solid gainsboro;
  border-radius: 5px;
  max-height: 400px;
  min-height: 400px;
  overflow: hidden;
  padding: 0 10px;
}

.important-info > p {
  font-size: 14px;
  padding: 10px 10px;
}

.ii-table {
  min-height: 350px;
  max-height: 350px;
  width: 100%;
  overflow-y: scroll;
}

h3.iit-heading {
  background: #b0bec5;
  padding: 5px;
  margin: 10px 0;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.tab-groups {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tab-groups h4 {
  font-size: 16px;
  width: 38%;
  margin-bottom: 10px;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 10px;
}

.tab-groups p {
  width: 58%;
  font-size: 14px;
  margin-bottom: 10px;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 10px;
  padding-left: 4px;
}

.tab-groups p span {
  font-weight: 500;
  font-size: 20px;
  padding: 0 8px;
  font-family: system-ui;
}

.ii-table > h5 {
  font-size: 14px;
  padding: 10px 0;
}

.all-fees h5 {
  width: 100%;
  font-size: 14px;
}

.all-fees h4 {
  font-size: 14px;
  font-weight: 400;
}

.all-fees h5 {
  width: 100%;
  font-size: 14px;
  margin: 8px 0;
  background: #dcdcdc8c;
  padding: 3px;
  border-radius: 2px;
}

.calculated-bal {
  padding: 20px 10px;
}

.calculated-bal p span {
  padding-top: 12px;
  display: block;
  font-weight: 600;
  font-size: 14px;
}

.checked {
  padding: 10px;
}

.checked h3 {
  font-size: 16px;
  width: 100%;
}

.security-check {
  display: grid;
  justify-items: center;
  align-items: start;
  background: #e5fffb;
  border: 1px solid gainsboro;
  border-radius: 6px;
}

.security-check * {
  color: #000;
}

.security-check .sectionOne {
  display: inline-flex;
  width: 85%;
  border-bottom: 1px dashed gainsboro;
  padding: 20px 0;
}

.security-check .sectionOne label {
  padding-left: 12px;
}

.security-check .sectionOne label h3 {
  padding-bottom: 8px;
}

h2.notice-tag {
  font-size: 14px;
  font-weight: 400;
  margin: 21px 6px;
}

.tag-no-cred-sco {
  margin: 40px 0;
  width: 90%;
  background: #fff;
  padding: 10px;
  height: 150px;
  box-shadow: 1px 1px 6px #808080;
}

.tag-no-cred-sco p {
  font-size: 14px;
}

.tag-no-cred-sco p span {
  font-weight: 600;
}

.card button {
  padding: 6px 15px;
  font-weight: 700;
  border: 1px solid;
  background: #fed100;
  border-radius: 4px;
  border-color: #004d40;
}

.card button:hover {
  background: #004d40;
  color: #fff;
  transition: 500ms;
}

@media(max-width: 500px){
  .card-select {
    display: flex;
    flex-wrap: wrap;
}

.card-view {
  width: 100%;
}

.cards-list {
  width: 100%;
}

.information {
  padding: 11px 10px 11px 20%;
}
}