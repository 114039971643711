.dashboard-footer .cont {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 5%;
    z-index: 8;
}

.dashboard-footer .cont ul {
    width: 80%;
    display: flex;
    justify-content: space-around;
    background: var(--pri-col);
    padding: 11px 4px;
    border-radius: 33px;
}

.dashboard-footer .cont ul li {
    width: 18%;
    display: flex;
    justify-content: center;
    padding: 4px 0;
}

.dashboard-footer .cont ul li.active {
    background: #b5d3eb;
    border-radius: 5px;
}

.dashboard-footer .cont ul li.active a * {
    color: #02332a;
}

.dashboard-footer .cont ul li a {
    text-align: center;
}

.dashboard-footer .cont ul li svg {
    color: var(--sec-col);
    font-size: 27px;
}

.dashboard-footer .cont ul li h3 {
    color: #fff;
    font-size: 15px;
}

.nav-menu {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: -100vw;
    background: #0000004d;
    transition: 1s ease;
    backdrop-filter: blur(2px);
}

.nav-menu .inner-cont {
    width: 33%;
    height: 100%;
    background: #fff;;
}

.nav-menu.activeMobileNav {
    left: 0;
    transition: 1s ease;
    z-index: 9;
}

.nav-menu .inner-cont div#navClose {
    height: 11vh;
    background: var(--pri-col);
    padding: 4px;
    box-shadow: 0px 3px 7px 0px #000;
}

.inner-cont div#navClose h3 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-cont div#navClose h3 svg {
    color: #fff;
    font-size: 30px;
}

ul.nav-links.mob {
    padding: 4px;
}

ul.nav-links.mob li {
    padding: 1px;
}

ul.nav-links.mob li a {
    color: var(--pri-col);
    font-weight: 500;
    width: 100%;
    height: 100%;
    display: block;
    padding: 11px 8px;
}

ul.nav-links.mob ul {
    height: 0;
    overflow: hidden;
    transition: 1s ease;
}

ul.tranzact-mob-menu {
    height: 135px !important;
    transition: 1s ease;
    background: var(--sec-col);
    border-radius: 8px;
}

ul.prof-mob-menu {
    height: 90px !important;
    transition: 1s ease;
    background: var(--sec-col);
    border-radius: 8px;
}

@media (max-width: 800px) {
    .nav-menu .inner-cont {
        width: 50%;
    }
}

@media (max-width: 550px) {
    .nav-menu .inner-cont {
        width: 75%;
    }

    .dashboard-footer .cont ul {
        width: 95%;
        padding: 8px 4px;
    }

    .dashboard-footer .cont ul li svg {
        font-size: 24px;
    }

    .dashboard-footer .cont ul li h3 {
        font-size: 13px;
    }

    ul.nav-links.mob li a {
        font-weight: 600;
        font-size: 15px;
    }
}
