.contact-support {
    position: relative;
    width: 100%;
    padding: 40px 0;
    background-image: url(../assets/cont-sup.jpg);
    background-size: cover;
    background-position: center;
}

.contact-support .cont {
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.contact-support .cont h2 {
    padding-bottom: 21px;
}

.contact-support .cont h5 {
    color: #fff;
    padding-bottom: 21px;
}

.contact-support .cont form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.contact-support .cont form input {
    width: 100%;
}

.contact-support .cont form textarea {
    width: 50%;
    max-height: 100px;
    min-height: 100px;
    max-width: 43%;
    min-width: 43%;
    border: unset;
    border-radius: 3px;
    font-size: 15px;
}

.contact-support .cont form button {
    width: 50%;
    margin: 24px;
    background: var(--svg);
}

@media(max-width: 500px){
    .contact-support .cont form {
        display: flex;
        flex-wrap: wrap;
    }

    .contact-support .cont form textarea {
        width: 50%;
        max-width: 97%;
        min-width: 97%;
    }
}