.profile {
    min-height: 100vh;
    width: 100vw;
}

.profile * {
    color: var(--pri-col);
}

.profile .cont {
    width: 100%;
}

.profile .cont .headt {
    background: var(--sec-col-lite);
    text-align: center;
    padding: 8px;
}

.profile .cont .prof-dets {
    width: 80%;
    margin: 21px auto;
    border: 3px outset var(--pri-col);
    padding: 11px;
    border-radius: 8px;
    box-shadow: 2px 2px 4px 1px var(--pri-col);
}

.profile .cont .prof-dets .bio-data {
    padding: 11px 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.profile .cont .prof-dets .img {
    background: var(--pri-col);
    width: 150px;
    height: 150px;
    border-radius: 8px;
    margin-right: 33px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 2px 2px 2px gray;
}

.profile .cont .prof-dets h3 {
    text-align: center;
    font-size: 21px;
    text-transform: uppercase;
    padding: 4px 0;
    background: var(--sec-col);
    border: 1px solid;
    border-radius: 4px;
}

.profile .cont .prof-dets h4 {
    font-weight: 500;
    padding: 4px 0;
    font-size: 13px;
}

.profile .cont .prof-dets h4:after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 1px dotted;
}

.profile .cont .prof-dets h4 span {
    font-weight: 600;
    font-size: 15px;
}

@media(max-width: 500px){
    .profile .cont .prof-dets {
        width: 95%;
    }
    .profile .cont .prof-dets .bio-data {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profile .cont .prof-dets .img {
        margin-right: 0px;
    }

    .profile .cont .prof-dets .names {
        width: 100%;
        padding: 21px 0;
    }

    .home-address, .business-address {
        padding-bottom: 21px;
    }
}