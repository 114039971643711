.cpassword {
    width: 100%;
    min-height: 100vh;
    padding-bottom: 33px;
}

.cpassword *{
    color: #fff;
}

.passwod-header {
    height: 20vh;
    display: grid;
    align-items: end;
    justify-items: center;
    padding-bottom: 21px;
}

.passwod-header h2{
    font-family: 'Lobster', cursive;
    color: #b5d3eb;
}

.password-container {
    width: 80%;
    margin: 21px auto;
    border: 1px solid #2474c8;
    border-radius: 8px;
    padding: 21px;
    background: #000;
}