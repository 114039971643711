.services {
    width: 100%;
}

.services .cont {
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
}

.services .cont > p {
    padding: 15px 0;
    width: 70%;
}

.services .cont ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.services .cont ul li {
    width: 45%;
    padding: 2% 3%;
    border: 2px solid var(--svg);
    margin: 3% 0;
    border-radius: 8px;
    box-shadow: 1px 1px 4px var(--pri-col);
}

.services .cont ul li.fes:hover{
    background-image: url('../assets/service01.jpg');
    position: relative;
    transition: 500ms ease-in-out;
}

.services .cont ul li.sec:hover{
    background-image: url('../assets/service02.jpg');
    position: relative;
    transition: 500ms ease-in-out;
}

.services .cont ul li.tord:hover{
    background-image: url('../assets/service03.jpg');
    position: relative;
    transition: 500ms ease-in-out;
}

.services .cont ul li  > svg {
    font-size: 69px;
    padding: 15px 0;
    color: var(--svg);
}

.services .cont ul li:hover svg {
    color: #fff;
    position: relative;
}

.services .cont ul li:hover:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #000000ba;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}

.services .cont ul li:hover svg,
.services .cont ul li:hover h2, 
.services .cont ul li:hover p {
    color: #fff;
    position: relative;
}

.services .cont ul li h2 {
    color: var(--pri-col);
    padding-bottom: 13px;
}

.services .cont ul li p {
    padding-bottom: 22px;
}

.services .cont ul li a {
    background: var(--pri-col);
    padding: 10px;
    border-radius: 15px 0;
    color: #fff;
    margin: 13px 0;
    display: block;
    width: 60%;
    text-align: center;
    position: relative;
}

.services .cont ul li a:hover {
    background: #c1000047;
    backdrop-filter: blur(12px);
    color: #fff;
    border: 1px solid;
    transition: 500ms ease;
    position: relative;
    bottom: 4px;
    right: 4px;
}

@media(max-width: 500px){
    .services .cont > p {
        width: 85%;
    }

    .services .cont ul li h2 {
        font-size: 14px;
        color: var(--pri-col);
        padding-bottom: 13px;
    }

    .services .cont ul li > svg {
        font-size: 36px;
        padding: 5px 0;
    }

    .services .cont ul li a {
        width: 92%;
        margin: 3px 0;
        border-radius: 11px 0;
    }

    .services .cont ul li p {
        padding-bottom: 10px;
    }
    
}