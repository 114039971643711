.withdraw {
    width: 100%;
    min-height: 100vh;
    padding-bottom: 33px;
    background-image: url(../../dashboard/assets/auth.jpg);
    background-position: center;
    background-size: cover;
}

.withdraw * {
    color: #fff;
}

.withdraw-header {
    height: 11vh;
    display: grid;
    align-items: end;
    justify-items: center;
    padding-bottom: 21px;
}

.withdraw-header h2 {
    font-family: 'Lobster', cursive;
    color: #fff;
}

.withdraw-form {
    width: 70%;
    margin: 33px auto;
}

.withdraw-form form {
    align-items: center;
    backdrop-filter: blur(18px);
    border: 2px inset #000;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding-bottom: 3%;
    width: 100%;
    box-shadow: 3px 3px 4px 2px #000;
}

.withdraw-form form h4 {
    color: #1674bf;
    margin: 8px;
    width: 80%;
    text-align: center;
}

.withdraw-form form h4 span {
    color: #02d3ed;
}

.withdraw-form form select#wallet {
    width: 80%;
    height: 30px;
    background: #f0f8ff00;
    border-radius: 4px;
    margin: 18px 0 0;
}

.withdraw-form form button {
    background: var(--svg);
    border: 1px solid #000;
    border-radius: 4px;
    color: #fff;
    height: 33px;
    width: 80%;
    margin-top: 11px;
}

.withdraw-form form button:hover {
    background: #054a81;
    color: #7ebdf3;
    transition: 500s ease-in-out;
}

.withdraw-form form .total {
    width: 80%;
    height: 70px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 8px 0;
    margin-top: 11px;
}

.withdraw-form form .total h3 {
    color: var(--buttoned);
    padding: 6px;
    width: 100%;
    height: 90%;
    text-align: center;
    position: relative;
    border: 1px solid var(--buttoned);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.withdraw-form form .total h3 span {
    background: var(--buttoned);
    color: var(--svg);
    font-size: 9px;
    left: 9px;
    letter-spacing: 3px;
    padding: 3px 6px;
    position: absolute;
    top: -12px;
    border-radius: 4px;
}

@media (max-width: 500px) {
    .withdraw-form {
        width: 90%;
    }
.withdraw-form form .total {
    width: 90%;
}

.withdraw-form form select#wallet {
    width: 90%;
}
}