.socials-right form {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-bottom: 15px;
}

.socials-right form.filler input {
    margin-right: 0 !important;
}

.socials-right form button {
    color: var(--pri-col);
    border: 1px solid;
    padding: 4px;
    border-radius: 4px;
    background: #fff;
}

.social-handles {
    background: var(--sec-col-lite);
    padding-top: 21px;
}

@media(max-width: 500px){

}