.trans {
    min-height: 100vh;
    width: 100%;
    padding-bottom: 33px;
}

.trans-header {
    height: 11vh;
    display: grid;
    align-items: end;
    justify-items: center;
    padding-bottom: 21px;
}

.transactions {
    margin: 0 auto;
    display: grid;
    justify-content: center;
}

table.transact {
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 5px 5px 0 rgb(36 116 200 / 28%);
}

table.transact thead {
    background: #648eb3;
}

table.transact thead tr th {
    padding: 7px 3px;
    color: var(--sec-col-lite);
    font-size: 10px;
}

table.transact thead tr th:first-child {
    text-align: left;
    border-right: 1px solid;
}

table.transact thead tr th:last-child {
    text-align: right;
    border-left: 1px solid;
}

table.transact tbody {
    background: #000;
}

table.transact tbody tr td {
    padding: 5px 4px;
    font-size: 11px;
    text-align: center;
}

table.transact tbody tr td:first-child {
    text-align: left;
}

table.transact tbody tr td:last-child {
    text-align: right;
}

table.transact tbody tr td.idid {
    color: #d7d700;
}

table.transact tbody tr td.amount {
    color: #02ff02;
}

table.transact tbody tr td.wallet span {
    padding: 2px;
    border-radius: 3px;
    background: #3f3c77;
    color: #fff;
    font-size: 11px;
}

table.transact tbody * {
    color: #fff;
}

.trans-header h2 {
    color: var(--pri-col);
}

@media(max-width: 500px){
    .transactions {
        width: 90%;
        margin: 0 auto;
        display: grid;
        justify-content: unset;
    }

    table.transact thead {
        display: none;
    }

    table.transact tbody tr:nth-child(odd) {
        background-color: #232323;
    }

    table.transact tbody tr td {
        display: block;
        padding-left: 45% !important;
        text-align: right !important;
        border-top: 1px solid #3e3e3e !important;
    }

    table.transact [data-label] {
        position: relative;
        font-size: 12px !important;
    }

    table.transact [data-label]::before {
        display: block;
        position: absolute;
        content: attr(data-label);
        font-weight: 700;
        color: #ffffff;
        top: 0;
        left: 0;
        padding: 5px 15px;
        font-size: 12px;
    }
}