.psetting {
    width: 100%;
    min-height: 100vh;
    padding-bottom: 21px;
}

.psetting * {
    color: #fff;
}

.set-heading {
    height: 20vh;
    display: grid;
    align-items: end;
    justify-items: center;
    padding-bottom: 21px;
}

.set-heading h2 {
    font-family: 'Lobster', cursive;
    color: #b5d3eb;
}

.set-cont {
    width: 80%;
    margin: 21px auto;
    border: 1px solid #2474c8;
    border-radius: 8px;
    padding: 21px;
    background: #000;
}

.set-cont form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.set-cont form .ppix {
    width: 35%;
    padding: 13px;
    display: grid;
    justify-items: center;
    align-items: center;
}

.set-cont form .ppix .pix {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    /* background: #0c325b; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.set-cont form ul.names {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.set-cont form ul.names li {
    width: 48%;
    position: relative;
    height: 40px;
    margin: 18px 0;
}

.set-cont form ul li input {
    width: -webkit-fill-available;
    height: 100%;
    border: none;
    border-bottom: 2px solid grey;
    font-size: 18px;
    background: transparent;
    color: #fff;
    padding: 0;
}

.set-cont form ul.address {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.set-cont form ul.address li {
    width: 30%;
    position: relative;
    height: 40px;
    margin: 18px 0;
}

.set-cont form button {
    width: 100%;
}

.set-cont form ul li input:focus-visible{
    outline: none;
}

.set-cont form ul li .underline {
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
}

.set-cont form ul li .underline:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: #69b2ff;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.set-cont form ul.password {
    width: 30%;
    margin: 21px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
}

.set-cont form ul.password li {
    position: relative;
    height: 40px;
    margin: 18px 0;
}

.set-cont form ul li input:focus ~ .underline:before{
    transform: scaleX(1);
}

.set-cont form ul li label{
    position: absolute;
    top: -10px;
    left: 0;
    color: gray;
    pointer-events: none;
    transition: all 0.3s ease;
}

.set-cont form ul li input:focus ~ label{
    transform: translateY(-8px);
    font-size: 15px;
    color: #69b2ff;
}

.set-cont form button {
    width: 100%;
    background: #0b3f69;
    border: 1px solid #2196F3;
    padding: 8px;
    border-radius: 4px;
}

.set-cont form button:hover {
    background: #0c4a7d;
    box-shadow: 0 0 5px #03A9F4;
    transition: 700ms ease;
}

@media (max-width: 1100px) {
    .set-cont {
        width: 90%;
    }

    .set-cont form ul li input {
        font-size: 16px;
    }
}

@media (max-width: 500px) {
    .set-cont form .ppix {
        width: 100%;
    }

    .set-cont form .ppix .pix {
        width: 150px;
        height: 150px;
    }

    .set-cont form ul.names {
        width: 100%;
    }

    .set-cont {
        width: auto;
        margin: 21px 4px;
    }

    .set-cont form ul.names {
        width: 100%;
        justify-content: center;
    }

    .set-cont form ul.password {
        width: 80%;
        justify-content: center;
    }

    .set-cont form ul.names li {
        width: 80%;
    }

    .set-cont form ul.address {
        width: 100%;
        justify-content: center;
    }

    .set-cont form ul.address li {
        width: 80%;
    }
}

@media (max-width: 400px) {
    .set-cont form ul.names li {
        width: 90%;
    }

    .set-cont form ul.address li {
        width: 90%;
    }
}